import React from "react"
import { Link } from "gatsby-plugin-react-i18next";
import { useTranslation } from "react-i18next"
import DynamicImage from "../../../shared/components/dynamic-image"
import PreTitle from "../../../shared/components/pre-title"
import { useInView } from "react-intersection-observer";
import './index.scss'

const ClubsList = ({ }) => {
    const { t } = useTranslation();

    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '-100px 0px',
    });

    const items = t("clubs.items", { returnObjects: true })

    return (
        <div ref={ref} className={`clubs-list${inView ? ' in-view' : ''}`}>
            <div className="wrapper-fluid">
                <div className="grid">
                    <PreTitle className="pretitle" text={t("clubs.pretitle")} />
                    <h2 className="title">{t("clubs.subtitle")}</h2>
                    <div className="clubs-wrapper">
                        {
                            items.map((item, index) => {
                                return (
                                    <div key={`club-${index}`} className="club">
                                        <Link to={item.path} aria-label={`${t("clubs.showMore")} ${item.name} `}>
                                            <figure>
                                                <DynamicImage imageKey={item.icon} />
                                                <span className="btn stroked">{t("clubs.showMore")}</span>
                                            </figure>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClubsList