import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "./../shared/components/layout";
import PageHeader from "../shared/components/page-header";
import ClubsList from "./components/clubs-list";

const Clubs = (props) => {
    const { pageContext } = props
    const { t } = useTranslation();

    const metas = {
        title: t("seo.clubs.title"),
        description: t("seo.clubs.description")
    }

    return (
        <Layout
            metas={metas}
            pageContext={pageContext}
        >
            <PageHeader
                title={t("clubs.title")}
                description={t("clubs.description")}
            />
            <ClubsList />
        </Layout>
    );
};

export default Clubs;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
