import React from "react";
import './index.scss'
const PreTitle = ({ text, enter}) => {
  return (
    <h3 className="pre-title">
      <span></span> <span>{text}</span>
    </h3>
  );
};

export default PreTitle;
